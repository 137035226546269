






























































import { Component } from 'vue-property-decorator'

import { AddedToCartModalMixin } from '../../../../shared/mixins/addedToCartModal.mixin'
import { CartItem } from '../../../../checkout/molecules/CartItem'
import { Loader } from '../../../../shared/molecules/Loader'
import { toImageProps } from '../../../../shared/support'
import { AllowedImageRatio, AllowedImageWidth } from '../../../../../contexts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<Basic>({
  name: 'Basic',
  components: { SuggestedProductItem: CartItem, Loader }
})
export class Basic extends AddedToCartModalMixin {
  public get image () {
    return toImageProps(this.payload.product.image, AllowedImageRatio.Square, AllowedImageWidth.ProductCardSmall)
  }
}

export default Basic
